import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { CartwheelSelectOptions } from '@cartwheel/web-components';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { Client } from 'app/models/Client';
import { UserSettings } from 'app/models/user-settings';
import { InvoiceLine } from 'app/models/InvoiceLine';
import { UserCompanyRole } from '@cartwheel/web-components';
import { OpenClientAddDialogAction, SelectClientAction } from 'app/redux/actions/client';
import { CreateManualInvoiceAction, GenerateInvoicePreviewAction } from 'app/redux/actions/invoice';
import { getDashboardRole, getGSettingsGlobalSettingsModel, getInvoiceLoading, getLoadedClients, getSelectedClient, State } from 'app/redux/reducers';
import { ResponsiveService } from 'app/services/responsive.service';
import { PATTERNS } from 'app/shared/consts';
import { ClientStatus, InvoiceDueInterval, SuccessStatus } from 'app/shared/enums';
import { GetCompanyUsersAction } from 'app/redux/actions/user';
import { CompanyUserPagedRequest } from 'app/models/CompanyUser';

@Component({
  selector: 'app-generate-manual-invoice',
  templateUrl: './generate-manual-invoice.component.html',
  styleUrls: ['./generate-manual-invoice.component.scss']
})
export class GenerateManualInvoiceComponent implements OnInit {
  isMobile$: Observable<boolean>;
  invoicesLoading$: Observable<SuccessStatus>;
  successStatus = SuccessStatus;
  submitManualInvoiceForm: UntypedFormGroup;
  selectedClient: Client = null;
  clients: Client[] = [];
  clientSelectOptions: CartwheelSelectOptions<string> = [];
  amountValid: boolean;
  selectedDueDate: Date;
  invoiceTotal: number;
  getGlobalSettingsModel: UserSettings;
  dateValid = true;
  invoiceSettingLevel: 'basic' | 'advanced' = 'basic';
  loadingFromSpreadsheet: boolean = false;
  actionType: 'preview' | 'create' = null;
  private currentRole: UserCompanyRole;

  constructor(
    @Inject(MatDialogRef) public dialog: MatDialogRef<GenerateManualInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private fb: UntypedFormBuilder,
    private destroyRef: DestroyRef,
    private responsiveService: ResponsiveService,
  ) {
    this.initForm();
  }

  get disabled(): boolean {
    return !this.selectedClient?.invoiceEmails?.length ||
      !this.amountValid ||
      !this.invoiceLines.valid ||
      this.submitManualInvoiceForm.get('overridenDueDate').invalid ||
      this.submitManualInvoiceForm.get('invoiceEndDate').invalid;
  }

  get invoiceTotalTax(): number {
    return this.selectedClient?.taxRate ? Number(this.invoiceTotal) + Number(this.invoiceTotal * this.selectedClient?.taxRate.taxPercentage / 100) : this.invoiceTotal;
  }

  get invoiceLineControls(): UntypedFormGroup[] {
    return this.invoiceLines.controls as UntypedFormGroup[];
  }

  private get client() {
    return this.submitManualInvoiceForm.controls['client'];
  }

  private get invoiceAmount() {
    return this.submitManualInvoiceForm.controls['invoiceTotal'];
  }

  private get invoiceLines(): UntypedFormArray {
    return this.submitManualInvoiceForm.controls['invoiceLines'] as UntypedFormArray;
  }

  private get populatedInvoiceTerms() {
    return this.submitManualInvoiceForm.controls['invoiceTerms'];
  }

  private get overriddenInvoicePrefix() {
    return this.submitManualInvoiceForm.controls['overriddenInvoicePrefix'];
  }

  ngOnInit(): void {
    this.isMobile$ = this.responsiveService.getMobileStatus();
    this.invoicesLoading$ = this.store.select(getInvoiceLoading)
      .pipe(
        tap(s => {
          if (s === null) {
            this.actionType = null;
          }
        })
      );
    this.store.select(getLoadedClients)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(s => {
        this.clients = s.filter(c => c.status !== ClientStatus.Deleted && c.status !== ClientStatus.Archived);
        this.clientSelectOptions = this.clients.map(c => ({ label: c.clientName, value: c.clientID }));
        return this.clients;
      });

    this.store.select(getDashboardRole)
      .pipe(filter(s => !!s))
      .subscribe(role => {
        this.currentRole = role;
        this.store.dispatch(GetCompanyUsersAction(new CompanyUserPagedRequest()));
      });

    this.invoiceLines.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(s => Number(s.reduce((sum, current) => sum + (current.amount * current.quantity), 0)))
      )
      .subscribe(s => {
        this.invoiceTotal = s > 0 ? s : 0;
        this.amountValid = !!this.invoiceTotal;
      });

    this.invoiceAmount.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(s => {
        this.invoiceTotal = s > 0 ? s : 0;
        this.amountValid = !!this.invoiceTotal;
      });

    this.store.select(getSelectedClient)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.store.select(getGSettingsGlobalSettingsModel)),
      ).subscribe(([client, gModel])=> {
        this.getGlobalSettingsModel = gModel;
        this.overriddenInvoicePrefix.setValue(client?.invoicePrefix || gModel.companySettings?.invoiceNumberPrefix || this.overriddenInvoicePrefix.value);
        if (client) {
          this.client.setValue(client);
          this.selectedClient = client;
          this.selectedDueDate = this.getDueDate(this.selectedClient.invoiceDueInterval);
          this.populatedInvoiceTerms.setValue(this.selectedClient.invoiceTerms);
          if (this.invoiceLines.value.length) {
            this.invoiceLines.patchValue(
              this.invoiceLines.value.map(s => ({ ...s, clientId: client.clientID }))
            );
          }
        }
      });
  }

  invoiceEndDateFilter = (d: Date | null): boolean => {
    return [0, 5, 6].includes((d || new Date(new Date().setHours(0, 0, 0, 0))).getDay()) && d >= new Date(new Date().setHours(0, 0, 0, 0));
  };

  invoiceDueDateFilter = (d: Date | null): boolean => {
    return d >= new Date(new Date().setHours(0, 0, 0, 0));
  };

  addClient(): void {
    return this.store.dispatch(new OpenClientAddDialogAction())
  }

  selectClient(clientId: string): void {
    this.store.dispatch(new SelectClientAction(this.clients.find(s => s.clientID === clientId)));
  }

  onSubmit(): void {
    this.actionType = 'create';
    const clientId = this.selectedClient.clientID;
    this.store.dispatch(CreateManualInvoiceAction({
      invoiceData: {
        clientId: clientId,
        invoiceTotal: this.invoiceTotal,
        overridenDueDate: this.submitManualInvoiceForm.get('overridenDueDate').value,
        overriddenInvoicePrefix: this.overriddenInvoicePrefix.value,
        invoiceEndDate: this.submitManualInvoiceForm.get('invoiceEndDate').value,
        invoiceTerms: this.populatedInvoiceTerms.value,
        invoiceLines: this.invoiceLines.value
      },
      companyId: this.currentRole.companyId.toString()
    }));

  }

  previewInvoice(): void {
    this.actionType = 'preview';
    this.store.dispatch(GenerateInvoicePreviewAction({
      invoiceData: {
        clientId: this.selectedClient.clientID,
        invoiceTotal: this.invoiceTotal,
        overridenDueDate:  this.submitManualInvoiceForm.get('overridenDueDate').value,
        overriddenInvoicePrefix: this.overriddenInvoicePrefix.value,
        invoiceEndDate: this.submitManualInvoiceForm.get('invoiceEndDate').value,
        invoiceTerms: this.populatedInvoiceTerms.value,
        preview: true,
        invoiceLines: this.invoiceLines.value,
      },
      companyId: this.currentRole.companyId.toString()
    }));
  }
  checkDateValid(value: Date): void {
    const parsedDate = new Date(new Date(value).setHours(1, 0, 0, 0));
    const currentDate = new Date(new Date().setHours(1, 0, 0, 0));
    this.dateValid = parsedDate > currentDate;
  }

  changeInvoiceSetting(event: MatRadioChange): void {
    // event.value will be basic or advanced
    this.invoiceSettingLevel = event.value;
    if (event.value === 'basic') {
      this.invoiceLines.clear()
    } else {
      this.invoiceAmount.reset();
      this.addInvoiceLine();
    }
  }

  updateManualInvoiceSettings(setting: 'basic'|'advanced')  {
    const isChange = this.invoiceSettingLevel !== setting;
    this.invoiceSettingLevel = setting;
    if(isChange) {
      if (setting === 'basic') {
        this.invoiceLines.clear();
        this.loadingFromSpreadsheet = false;
      } else {
        this.invoiceAmount.reset();
        this.loadingFromSpreadsheet = true;
      }
    }
  }

  addInvoiceLine(): void {
    this.invoiceLines.push(
      this.fb.group({
        primaryValue: ['', Validators.required],
        secondaryValue: '',
        amount: [0, [Validators.required, Validators.min(1), Validators.pattern(PATTERNS.Decimal)]],
        companyId: [this.currentRole.companyId.toString(), Validators.required],
        clientId: [this.selectedClient?.clientID, Validators.required],
        quantity: [1, [Validators.required, Validators.min(1), Validators.pattern(PATTERNS.Decimal)]],
        ord: [this.invoiceLines.length]
      })
    );
  }

  addInvoiceLineFromSheet (input: InvoiceLine): void {
    this.invoiceLines.push(
      this.fb.group({
        primaryValue: [input.primaryValue, Validators.required],
        secondaryValue: input?.secondaryValue || '',
        amount: [input.amount, [Validators.required, Validators.min(1), Validators.pattern(PATTERNS.Decimal)]],
        quantity: [input.quantity ?? 1, [Validators.required, Validators.min(1), Validators.pattern(PATTERNS.Decimal)]],
        companyId: [this.currentRole.companyId.toString(), Validators.required],
        clientId: [this.selectedClient?.clientID, Validators.required],
        ord: [this.invoiceLines.length]
      })
    );
  }

  deleteInvoiceLine(index: number): void {
    this.invoiceLines.removeAt(index);
  }

  private initForm(): void {
    this.submitManualInvoiceForm = this.fb.group({
      'overriddenInvoicePrefix': '',
      'overriddenInvoicePrefixPreview': [{value: '', disabled: true}],
      'invoiceTotal': [this.invoiceTotal, [Validators.required, Validators.min(0), Validators.pattern(PATTERNS.Decimal)]],
      'client': [null, [Validators.required, this.validateClientIsUsable()]],
      'overridenDueDate': ['', [Validators.required]],
      'invoiceEndDate': ['', [Validators.required]],
      'invoiceTerms': '',
      'invoiceLines': this.fb.array([])
    });

    this.overriddenInvoicePrefix.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(prefix => this.submitManualInvoiceForm.controls.overriddenInvoicePrefixPreview.setValue(prefix + "001"));
  }

  private getDueDate(invoiceDueInterval: number | InvoiceDueInterval): Date {
    const date = new Date();
    let numDays = 0;
    switch (invoiceDueInterval) {
      case 0: {
        numDays = 7;
        break;
      }
      case 1: {
        numDays = 14;
        break;
      }
      case 2: {
        numDays = 30;
        break;
      }
      case 3: {
        numDays = 45;
        break;
      }
      case 4: {
        numDays = 60;
        break;
      }
      default:
        numDays = 7;
    }

    date.setDate(date.getDate() + numDays);
    return date;
  }

  private validateClientIsUsable(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: Client | null = control.value;
      if (!value) {
        return { invalidClient: true };
      }

      if (value.invoiceEmails?.length > 0) {
        return null;
      }
      return { invoiceEmailsAvailable: false };

    }
  }
}
