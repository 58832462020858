import { AfterViewInit, Component, DestroyRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyInvoice, CompanyInvoiceHistoryItem, CompanyInvoiceHistoryPagedRequest } from 'app/models/company-invoice';
import { PagedResponse } from 'app/models/pagination';
import { GetInvoiceHistoryAction } from 'app/redux/actions/invoice';
import { State, getInvoiceHistory, getInvoiceHistoryLoading } from 'app/redux/reducers';
import { HistoryItemType, SuccessStatus } from 'app/shared/enums';
import { HistoryItem } from '@cartwheel/web-components';

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss']
})
export class InvoiceHistoryComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public history: PagedResponse<CompanyInvoiceHistoryItem>;
  @Input() public invoice: CompanyInvoice;
  @ViewChild(MatPaginator) private paginator: MatPaginator;

  public loading$: Observable<boolean>;
  public dataSource: MatTableDataSource<HistoryItem> = new MatTableDataSource<HistoryItem>([]);
  public selectedType: HistoryItemType = null;
  public historyTypes: { label: string; value: HistoryItemType }[] = [
    { label: 'All', value: null },
    { label: 'Invoice Created', value: HistoryItemType.InvoiceCreated },
    { label: 'Invoice Email Sent', value: HistoryItemType.InvoiceEmailSent },
    { label: 'Invoice Email Delivered', value: HistoryItemType.InvoiceEmailDelivered },
    { label: 'Invoice Email Opened', value: HistoryItemType.InvoiceEmailOpened },
    { label: 'Invoice Link Clicked', value: HistoryItemType.InvoiceLinkClicked },
    { label: 'Invoice Payment Failed', value: HistoryItemType.InvoicePaymentFailed },
    { label: 'Invoice Payment Succeeded', value: HistoryItemType.InvoicePaymentSucceeded }
  ];
  private takeObservable: boolean = true;

  constructor(
    private store: Store<State>,
    private destroyRef: DestroyRef
  ) {
  }

  public ngOnInit(): void {
    this.loading$ = this.store.select(getInvoiceHistoryLoading).pipe(map(s => s === SuccessStatus.IsLoading));
    this.store.select(getInvoiceHistory)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(history => {
        this.history = history;
        this.dataSource.data = history.data.map(s => ({dateTime: s.historyDateTime, description: s.historyDescription}));
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.invoice?.currentValue) {
      this.selectedType = null;
    }
  }

  public ngAfterViewInit(): void {
    this.paginator.page
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(s => this.getInvoiceHistory(s.pageIndex + 1));
  }

  public filterByType(val: HistoryItemType): void {
    this.selectedType = val;
    this.paginator.firstPage();
    if (this.history.total <= 8) {
      this.getInvoiceHistory(1);
    }
  }

  private getInvoiceHistory(pageNumber: number): void {
    var payload: CompanyInvoiceHistoryPagedRequest = {
      limit: 8,
      page: pageNumber,
      invoiceId: this.invoice.invoiceId
    }
    if (this.selectedType != null) {
      payload.filterBy = this.selectedType;
    }

    this.store.dispatch(GetInvoiceHistoryAction({
      payload: payload
    }));
  }
}
