<div [formGroup]="formGroup">
  <div class="grid grid-cols-2 gap-x-[10%] gap-y-20">
    <cartwheel-input label="Primary value" [inputFormControl]="formGroup.get('primaryValue') | formControlType" />
    <cartwheel-input label="Secondary value" [inputFormControl]="formGroup.get('secondaryValue') | formControlType" />
    <cartwheel-input type="number" [label]="shownQuantity ? 'Rate' : 'Amount'" [inputFormControl]="formGroup.get('amount') | formControlType" />
    @if (shownQuantity) {
      <cartwheel-input label="quantity" type="number" [inputFormControl]="formGroup.get('quantity') | formControlType" />
    }
    <cartwheel-datepicker label="Created At" [dateFormControl]="formGroup.get('createdAt') | formControlType" />
  </div>
</div>